
//import styles from '../styles/home.module.css';
//import { BackgroundColorContext } from "../contexts/BackgroundColorContext";
import Dashboard from "../components/templates/DashboardTemplate/DashboardPageTemplate"
import React from "react";
function Home() {

  //const mainPanelRef = useRef(null);
  const tempStyle = {
    zIndex: 1,
    clear: "both"
  };

  return (
    <>
      <Dashboard style={tempStyle}>
      </Dashboard>
    </>
  ); 
}

export default Home;
