// SignupForm.js
import styles from '../../styles/signin.module.css';
import React, { useState } from 'react';
import Modal from '../../components/mocules/modals/AlertModal'; ;
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
const SignupForm = () => {
  
  //이메일, 비밀번호, 비밀번호 확인
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  //오류메시지 상태저장
  const [emailMessage, setEmailMessage] = useState('')
  const [passwordMessage, setPasswordMessage] = useState('')
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState('')

  // 유효성 검사
  const [isEmail, setIsEmail] = useState(false)
  const [isPassword, setIsPassword] = useState(false)
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(false)

  const [showModal, setShowModal] = useState(false); 
  const [modalMessage, setModalMessage] = useState(''); 
  
  const onChangeEmail = async (e) => {
    const emailRegex =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    const emailCurrent = e.target.value
    setEmail(emailCurrent)

    if (!emailRegex.test(emailCurrent)) {
      setEmailMessage('invalid e-mail format')
      setIsEmail(false)
    } else {
      setEmailMessage('valid e-mail format')
      setIsEmail(true)
    }
  };

  const onChangePassword = async(e) => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
    const passwordCurrent = e.target.value
    setPassword(passwordCurrent)

    if (!passwordRegex.test(passwordCurrent)) {
      setPasswordMessage('숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요')
      setIsPassword(false)
    } else {
      setPasswordMessage('안전한 비밀번호에요.')
      setIsPassword(true)
    }
  }
  
  const onChangePasswordConfirm = async(e) => {
      const passwordConfirmCurrent = e.target.value
      setPasswordConfirm(passwordConfirmCurrent)

      if (password === passwordConfirmCurrent) {
        setPasswordConfirmMessage('Correct Password')
        setIsPasswordConfirm(true)
      } else {
        setPasswordConfirmMessage('Incorrect Password')
        setIsPasswordConfirm(false)
      }
    }
  
  const handleSignup = async (e) => {
    e.preventDefault();
    try {
        const signupInfo = {
            email: email,
            password: password
        }
      const response = await fetch(`${API_BASE_URL}/api/auth/users/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signupInfo),
      });

      if (response.ok) {
        setModalMessage('SignUp Success. Try Login with new account.');
        setShowModal(true);
      } else {
        setModalMessage('SignUp failed. Please check your information again.');
        setShowModal(true);
        console.error('SignUp failed');
      }
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <>
    <form action="#" className={styles['signup-form']} onSubmit={handleSignup}>
          <input className={styles['input-box']}
            type="text"
            placeholder="Email"
            id="email"
            value={email}
            onChange={onChangeEmail} />  
        <div className={styles['description-message']}>
          {emailMessage}
        </div>

          <input className={styles['input-box']}
            type="password"
            placeholder="Password"
            id="password"
            value={password}
            onChange={onChangePassword}
          />
          <div className={styles['description-message']}>
            {passwordMessage}
          </div>

          <input className={styles['input-box']}
            type="password"
            placeholder="PasswordConfirm"
            id="passwordConfirm"
            value={passwordConfirm}
            onChange={onChangePasswordConfirm}
          />
          <div className={styles['description-message']}>
            {passwordConfirmMessage}
          </div>

          <button className={`${!(isEmail && isPassword && isPasswordConfirm) ? styles['auth-button'] : styles['signup-active-button']}`} 
            type="submit" 
            disabled={!(isEmail && isPassword && isPasswordConfirm)} >
            Sign Up
          </button>
    </form>
    {showModal && <Modal message={modalMessage} onClose={() => setShowModal(false)} />}
    </>
  );
};

export default SignupForm;
