import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'; //reboot.css를 불러옴
import './styles/global.css'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//  <React.StrictMode> // strictmode는 렌더링이 2번씩 됨
//    {/* <link rel="stylesheet" href={styles}></link> */}
	<App />
		//  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
