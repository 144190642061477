import React from 'react'
import styles from '../../styles/sidebar.module.css';
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as BiIcons from "react-icons/bi";
export const sidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        className: styles['sidebar-text']
    },
    {
        title: 'Content',
        path: '/content',
        icon: <BiIcons.BiSolidBookContent/>,
        className: styles['sidebar-text']
    },
    {
        title: 'Strategy',
        path: '/strategy',
        icon: <IoIcons.IoIosPaperPlane/>,
        className: styles['sidebar-text']
    },
    
]