import React, { createContext, useContext, useState, useEffect } from 'react';

export  const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState(
    localStorage.getItem('email') || 'none'
  )
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('isLoggedIn') === 'true' || false
  );
  
  
  // Define a function to set the login status and update localStorage
  const setLoginStatus = (status) => {
    setIsLoggedIn(status);
    localStorage.setItem('isLoggedIn', status);
  };

  const login = (email) => {
    setEmail(email)
    localStorage.setItem('email', email);
    setLoginStatus(true);
  };

  const logout = () => {
    localStorage.removeItem('email');
    setLoginStatus(false);
  };

  // Use useEffect to set the login status from localStorage during component mount
  useEffect(() => {
    const storedStatus = localStorage.getItem('isLoggedIn') === 'true' || false;
    setIsLoggedIn(storedStatus);
  }, []);

  return (
    <AuthContext.Provider value={{ email, isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);