import React from 'react';
import ReactDOM from 'react-dom';

const ModalPortal = ({ children, className }) => {
	  const modalRoot = document.getElementById('modal-root');
	  return ReactDOM.createPortal(
			<div className={className}>
				{children}
			</div>
			, modalRoot);
};




const Modal = ({ isOpen, className, children }) => {
	const handleModalClick = (e) => {
		    // 모달 외부에서 발생한 클릭 이벤트인 경우 동작 중지
		     e.stopPropagation();
	       };
	if(!isOpen) return null;
	return (
		<ModalPortal className={className}>
		<div className="modal" tabIndex="-1" role="dialog" style={{ display: isOpen ? 'block' : 'none' }}>
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					{children}
				</div>
			</div>
		</div>
		</ModalPortal>
	);
};



const ModalHeader = ({ children}) => {
	return (
		<div className="modal-header">
			<h5 className="modal-title">{children}</h5>
		</div>
	);
};



const ModalBody = ({ children, className }) => {
	return (
		<div className= {`modal-body ${className}`}>
			{children}
		</div>
	);
};



const ModalFooter = ({ children }) => {
	return (
		<div className="modal-footer">
			{children}
		</div>
	);
};

export { Modal, ModalHeader, ModalBody, ModalFooter }
