import React, {useEffect, useRef, useContext} from 'react';
import styles from '../../../styles/sidebar.module.css';
import * as BiIcons from 'react-icons/bi';
import { sidebarData } from '../../icons/sidebarData';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { BackgroundColorContext } from '../../../contexts/BackgroundColorContext'
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

function getAccessToken() {
    return localStorage.getItem('access_token');
}

const Sidebar = ({isOpen, toggleMenu, setHeaderText}) => {
  //sidebar 바깥을 클릭했을 때 sidebar가 닫히도록 함
  const side = useRef()
  const handleClose = async (e) => {
    let sideArea = side.current;
    if(sideArea){
    let sideChildren = side.current.contains(e.target);
      if (isOpen && (!sideArea || !sideChildren)) {
        console.log("click out space");
        await toggleMenu();
      }
    }
  }
  // sidebar에서 메뉴를 선택했을 때 headertext가 바뀌도록 함
  const handleItemClick = (item) => {
    setHeaderText(item.title);
    //onItemClick(item); // Notify the parent about the clicked item
  };

  //sidebar에서 logout 버튼
  const Auth = useContext(AuthContext);
  const handleLogout = async (e) => {
    const access_token = getAccessToken();

    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/logout/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`,
        },
        body: ''
      });
      if (response.ok) {
        localStorage.clear()
        Auth.logout();
        return <Navigate to = "/"/>;
      } else {
        localStorage.clear()
        Auth.logout();
        return <Navigate to = "/"/>;
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  //isOpen에 따라 handleClose 함수를 EventListener에 등록
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect (() => {
    window.addEventListener('mousedown', handleClose);
    return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
      window.removeEventListener('mousedown', handleClose);
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOpen])

  return (
    <BackgroundColorContext.Consumer>
        {({ color }) => (
      <aside className={`${styles['sidebar']} ${isOpen ? styles.isOpen : ''}`} data={color}>
        <nav ref={side}>
        <ul className={styles['sidebar-menu']}>
          <div className={styles['info']}>
            <div>logo</div>
            &nbsp; &nbsp;
            <div className={styles['id']}> {Auth.email}</div>
          </div>
        {sidebarData.map((item, index) => {
          return (
            <li key={index} className={item.className} onClick={() => handleItemClick(item)}>
                <Link to={item.path}>
                &nbsp; 
                {React.cloneElement(item.icon, { size: 20 })}
                &nbsp; &nbsp; &nbsp;
                  <p>{item.title} </p>
                </Link>
            </li>
          )
        })}
        <div className={styles['sidebar-logout']} onClick={handleLogout}>
            <BiIcons.BiLogOut size="2em" />
        </div>
        </ul>
      </nav>
      </aside>
    )}
    </BackgroundColorContext.Consumer>

  );
}
export default Sidebar;
