import React, { useState } from 'react';
import styles from '../../styles/signin.module.css';
import LoginForm from './LoginForm.js';
import SignupForm from './SignupForm.js'


function SignIn() {
  const [isLoginFormActive, setIsLoginFormActive] = useState(true);
  const handleToggleForm = () => {
    setIsLoginFormActive(!isLoginFormActive);
  };

  return (
    <>
    <link rel="preconnect" href="https://fonts.gstatic.com"/>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"/>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap" rel="stylesheet"/>
      
      <div className={styles['signin-background']}>
        <div className={styles['first-circle']}></div>
        <div className={styles['second-circle']}></div>
        <div className={styles['wrapper']}>
          <div className={styles['title-text']}>
            <div className={styles['title']}> {isLoginFormActive ? 'Login Form' : 'Signup Form'} </div>
          </div>
          <div className={styles['slide-controls']}>
              <label className={styles['slide']} onClick={handleToggleForm}>
                Login
              </label>
              <div className={styles['slide']} onClick={handleToggleForm}>
                Signup
              </div>
              <div className={`${styles['slider-tab']} ${isLoginFormActive ? '' : styles['slideRight']}`}></div>
            </div>
              {isLoginFormActive ? <LoginForm /> : <SignupForm />}
        </div>
      </div>
    </>
  );
}

export default SignIn;