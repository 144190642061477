import React, { useState } from 'react';
import {  Button, Form, FormGroup, Label, Input} from 'reactstrap';
import api from '../../../services/api';
import QuillEditor from '../editors/QuillEditor';
import styles from '../../../styles/modal.module.css';
import '../../../styles/modal.css';
import { Modal, ModalHeader, ModalBody, ModalFooter} from '../../atoms/Modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



export const AddTickerModal = ({ isOpen, toggleModal, tickerList, setTickerList }) => {
  const [ticker, setTicker] = useState('');
  const [description, setDescription] = useState('');
  const [source, setSource] = useState('yfinance'); // 기본값 설정
  const [category, setCategory] = useState('stock');
	const [error, setError] = useState('');

  const addTicker = (newTicker) => {
    setTickerList([...tickerList, newTicker]);
  };

  const handleAddTicker = async () => {
    try {
      const response = await api.post('/data/ticker/', {
        ticker,
        description,
        source,
				category
      });
      toggleModal();
      addTicker(response.data);
    } catch (error) {
      console.error('Error adding ticker:', error);
      setError('Error adding ticker: ' + error.message);    
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Add Ticker</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="ticker">Ticker</Label>
            <Input type="text" name="ticker" id="ticker" value={ticker} onChange={e => setTicker(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input type="text" name="description" id="description" value={description} onChange={e => setDescription(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label for="source">Source</Label>
            <select className="form-control" id="source" value={source} onChange={e => setSource(e.target.value)}>
              <option value="yfinance">Yahoo Finance</option>
              <option value="fred">Fred</option>
            </select>
          </FormGroup>
					<FormGroup>
            <Label for="category">Category</Label>
            <select className="form-control" id="category" value={category} onChange={e => setCategory(e.target.value)}>
              <option value="stock">Stock</option>
              <option value="economic">Economic</option>
              <option value="commodity">Commodity</option>
            </select>
          </FormGroup>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleAddTicker}>Add</Button>
        <Button color="secondary" onClick={toggleModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};



export const DetailTickerModal = ({ isOpen, toggleModal, tickerInfo }) => {
	const [isEditing, setIsEditing] = useState(false);
	const toggleEditing = () => {
		setIsEditing(!isEditing);
	}
	const [detail, setDetail] = useState(tickerInfo.detail);
	console.log(detail)
	const handleEditDetail = async () => {
		try{
			const response = await api.post(`/data/ticker/${tickerInfo.id}`,{
				detail
			});
			toggleEditing();
		}
		catch(error) {
			console.error('Error saving detail:', error);
		}
	}
	console.log("this is ticker detail:", detail);
	return (
		//reactstrap modal은 react protal을 통해 root 외부에 모달이 생기기에 div로 감싸도 id가  적용이 안됨
		<Modal className='detail-modal' isOpen={isOpen} >
			<ModalHeader>Detail Ticker</ModalHeader>
			<ModalBody className='detail-modal-body'>
				{isEditing ? (<ReactQuill value={detail} onChange={setDetail} />) 
					: ( <div dangerouslySetInnerHTML={{ __html: detail}} /> )
					}
				</ModalBody>
					<ModalFooter>
						{isEditing ? (
							<>
								<Button color="primary" onClick={handleEditDetail}>Save</Button>
								<Button color="danger" onClick={toggleEditing}>Cancel</Button>
							</>
						)
						: (<Button color="primary" onClick={toggleEditing}>Edit</Button>)
						}
						<Button color="secondary" onClick={toggleModal}>Close</Button>
					</ModalFooter>
			</Modal>
					)
}


export const EditTickerModal = ({ isOpen, toggleModal, tickerInfo }) => {
    const [ticker, setTicker] = useState(tickerInfo ? tickerInfo.ticker:'');
    const [description, setDescription] = useState(tickerInfo ? tickerInfo.description:'');
    const [source, setSource] = useState(tickerInfo ? tickerInfo.source:'');
    const [category, setCategory] = useState('stock');
    const [error, setError] = useState('');
  
    const handleEditTicker = async () => {
      try {
        //데이터가 불러올 수 있는건지 확인하는 api
        const response = await api.post(`/data/ticker/${tickerInfo.id}`, {
          ticker,
          description,
          source,
					category
        });
        console.log(response.data);
        // 추가적인 작업 수행 가능
        // 예: 새로운 항목을 리스트에 추가하거나 모달을 닫음
        toggleModal();
      } catch (error) {
        console.error('Error adding ticker:', error);
        setError('Error Editing ticker: ' + error.message);    
      }
    };
  
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>Edit Ticker</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="ticker">Ticker</Label>
              <Input type="text" name="ticker" id="ticker" value={ticker} onChange={e => setTicker(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input type="text" name="description" id="description" value={description} onChange={e => setDescription(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="source">Source</Label>
              <select className="form-control" id="source" value={source} onChange={e => setSource(e.target.value)}>
                <option value="yfinance">Yahoo Finance</option>
                <option value="fred">Fred</option>
              </select>
            </FormGroup>
						<FormGroup>
							<Label for="category">Category</Label>
							<select className="form-control" id="category" value={category} onChange={e => setCategory(e.target.value)}>
								<option value="stock">Stock</option>
								<option value="economic">Economic</option>
								<option value="commodity">Commodity</option>
							</select>
						</FormGroup>
						{error && <p style={{ color: 'red' }}>{error}</p>}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleEditTicker}>Edit</Button>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  };

export const DeleteTickerModal = ({ isOpen, toggleModal, tickerInfo, setResponseState, setTickerList }) => {
  const removeTicker = (tickerToRemove) => {
    setTickerList(prev => {
      const newList = prev.filter(ticker => ticker !== tickerToRemove); // 삭제할 요소를 제외한 새로운 배열 생성
      console.log("this is newList", newList);
      return newList; // 새로운 배열을 반환하여 상태 업데이트
    });
  };
  const handleDelete = async () => {
      try {
        await api.delete(`/data/ticker/${tickerInfo.id}`);
        setResponseState('deleted');
        removeTicker(tickerInfo)
      } catch (error) {
        setResponseState('error');
      }
    };

    return (
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete ticker</ModalHeader>
          <ModalBody>
            Do you really want to delete this ticker?
          </ModalBody>
        <ModalFooter>
					<Button color="danger" onClick={handleDelete}>Delete</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  };

