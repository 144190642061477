// LoginForm.js
import styles from '../../styles/signin.module.css';
import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext'
//import Modal from '../../components/mocules/modals/AlertModal';
import {Button} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter} from '../../components/atoms/Modal';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
const LoginForm = () => {
    
    //이메일, 비밀번호
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // 유효성 검사
    const [isEmail, setIsEmail] = useState(false)
    const [isPassword, setIsPassword] = useState(false)

    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false); 
		const toggleAlertModal = () => {
			console.log("isAlertModalOpen", isAlertModalOpen);
			setIsAlertModalOpen(!isAlertModalOpen);
		}
    const Auth = useContext(AuthContext);
    
    
  const onChangeEmail = async (e) => {
    const emailRegex =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    const emailCurrent = e.target.value
    setEmail(emailCurrent)

    if (!emailRegex.test(emailCurrent)) {
      setIsEmail(false)
    } else {
      setIsEmail(true)
    }
  };

  const onChangePassword = async(e) => {
    const passwordCurrent = e.target.value
    setPassword(passwordCurrent)

    if (passwordCurrent) {
      setIsPassword(true)
    } else {
      setIsPassword(false)
    }
  }
    
    const handleLogin = async (e) => {
      e.preventDefault();
      try {
          const loginInfo = {
              email: email,
              password: password
          }
        const response = await fetch(`${API_BASE_URL}/api/auth/login/`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginInfo),
        });
        if (response.ok) {
          const data = await response.json();
          // Store the access_token in localStorage or sessionStorage
          const access_token = data.jwt_token.access_token; 
          localStorage.setItem('access_token', access_token);
          console.log(data.user.email);
		  Auth.login(data.user.email);
          return <Navigate to = "/"/>;
        } else {
					toggleAlertModal();
        }
      } catch (error) {
      }
    };

    return (
      <>
      <div>
      <form action="#" className={styles['login-form']} onSubmit={handleLogin}>
            <input className={styles['input-box']}
              type="text"
              placeholder="Email"
              id="email"
              value={email}
              onChange={onChangeEmail} />

            <input className={styles['input-box']}
              type="password"
              placeholder="Password"
              id="password"
              value={password}
              onChange={onChangePassword}
            />
            <div> Forget Password? </div>
            <button className={`${!(isEmail && isPassword) ? styles['auth-button'] : styles['login-active-button']}`} 
            type="submit" 
            disabled={!(isEmail && isPassword)} >
            Log In
          </button>

      </form>
			</div>
				<Modal isOpen={isAlertModalOpen} toggle={toggleAlertModal} >
					<ModalHeader> Login Failed</ModalHeader>
					<ModalBody>Please check your credentials</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={toggleAlertModal}>Close</Button>
					</ModalFooter>
				</Modal>
			</>
    );
};

export default LoginForm;
