import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { ClipLoader } from 'react-spinners';
import * as BiIcons from 'react-icons/bi';
import api from '../../../services/api';
import { DetailTickerModal, EditTickerModal, DeleteTickerModal } from '../../mocules/modals/TickerModal';
import styles from '../../../styles/chart.module.css';
import '../../../styles/chart.css';

export const ChartCard = React.memo(({index, tickerInfo, setTickerList}) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchDataForTicker(tickerInfo);
    }, 2000 * index); // 10초의 시간 지연
  
    return () => clearTimeout(timer); // 컴포넌트가 unmount되면 타이머를 정리합니다.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [responseState, setResponseState] = useState("loading");
  const [TickerData, setTickerData] = useState();
  
  useEffect(()=>{
  },[responseState])
 
  const fetchDataForTicker = async (tickerInfo) => {
    try {
      console.log("tickerinfo: ", tickerInfo);
      const response = await api.get(`/data/ticker/${tickerInfo.id}`)
      setTickerData(response.data);
      setResponseState('done');
    } catch (error){
      console.error('Error fetching data for ticker:', error);
      setResponseState('error');
    }
  };

  // console.log("this is Responsestate", ResponseState);
  const CustomCardHeader = ({ tickerInfo, setResponseState }) => {
		
		const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const toggleDetailModal = () => {
      console.log("is DetailModalOpen", isDetailModalOpen);
			setIsDetailModalOpen(!isDetailModalOpen);
    };
		const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const toggleEditModal = () => {
      setIsEditModalOpen(!isEditModalOpen);
		};
    
		const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const toggleDeleteModal = () => {
      setIsDeleteModalOpen(!isDeleteModalOpen);
    };
    
  
    // cardheader handler
    const handleRefresh = async () => {
      console.log("handleRefresh", tickerInfo);
      setResponseState('loading');
      await fetchDataForTicker(tickerInfo);
    };
		const category = tickerInfo.category;
		return (
			<div id='card-header--wrap'>
				<CardHeader className='card-header' category={category}>
					<Row>
						<Col>
							<div className="d-flex align-items-center justify-content-between">
								<h2 className='description'>{tickerInfo.description}</h2>
								<div>
									<button className={`btn btn-link btn-lg ${styles['action-button']}`} onClick={toggleDetailModal}>
										<BiIcons.BiDetail />
									</button>
									<button className={`btn btn-link btn-lg ${styles['action-button']}`} onClick={handleRefresh}>
										<BiIcons.BiRefresh />
									</button>
									<button className={`btn btn-link btn-lg ${styles['action-button']}`} onClick={toggleEditModal} >
										<BiIcons.BiEdit />
									</button>
									<button className={`btn btn-link btn-lg ${styles['action-button']}`} onClick={toggleDeleteModal} >
										<BiIcons.BiTrash />
									</button>
								</div>
							</div>
							<CardTitle className='ticker' tag="h7">{tickerInfo.ticker}</CardTitle>
						</Col>
					</Row>
				</CardHeader> 
				<DetailTickerModal isOpen={isDetailModalOpen} toggleModal={toggleDetailModal} tickerInfo={tickerInfo}/>
				<EditTickerModal isOpen={isEditModalOpen} toggleModal={toggleEditModal} tickerInfo={tickerInfo}/>
				<DeleteTickerModal isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} tickerInfo={tickerInfo} setResponseState={setResponseState} setTickerList={setTickerList}/>
			</div>
		);
  }

  const BasicChartCard = ({ tickerInfo, tickerData }) => {

    const jsonTickerData = JSON.parse(tickerData);

    // 변환된 데이터를 저장할 배열
    const seriesData = [];

    // 주어진 데이터를 Highcharts에서 사용할 수 있는 형식으로 변환
    // 날짜 170070700, 가격
    for (const dateString in jsonTickerData) {
      if (jsonTickerData.hasOwnProperty(dateString)) {
        //const timestamp = new Date(dateString).getTime(); // 날짜를 타임스탬프로 변환
        var intDate = parseInt(dateString)
        const price = jsonTickerData[dateString];
        seriesData.push([intDate, price]);
      }
    }   
    const options = {
      chart: {
        height: '280px',
        backgroundColor: '#27293D'
      },
      rangeSelector: {
				enable: false,
        //selected: 1,
        //labelStyle: {
        //  color: 'white'
        //},
      },
      title: {
        text: tickerInfo.title,
				style: {
					color: '#ffffff' // 타이틀 텍스트 색상을 흰색으로 지정
				}
			},
			xAxis: {
				labels: {
					style: {
						color: "#9a9a9a", // x축 레이블 텍스트 색상을 흰색으로 지정
					}
				},
				gridLineColor: 'rgba(29,140,248,0.0)',
				range: 6 * 30 * 24 * 3600 * 1000 * 10 
			},
			yAxis: {
				labels: {
					style: {
						color: "#9a9a9a", // y축 레이블 텍스트 색상을 흰색으로 지정
					}
				},
				gridLineColor: 'rgba(29,140,248,0.0)',
			},
			navigator: {
				enabled: true,
			},
      series: [{
        name: '주식 가격',
        data: seriesData,
        tooltip: {
          valueDecimals: 2
        }
      }],
//      colors: ['#ff0000', '#00ff00', '#0000ff'],
      backgroundColor: '#00ff00' // 배경색을 원하는 색상으로 지정

    };


    return (
      <>
        <Col md="12" lg="6" style={{ padding: 0 }}>
            <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} />
          </Col>
          <Col md="12" lg="6">
            <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} />              
        </Col>
      </>
    );
  };



    return (
			<div id='card--wrap'>
      <Card className='card'>
        <CustomCardHeader tickerInfo={tickerInfo} setResponseState={setResponseState} />
					<CardBody className='card-body'>
          	<Row style={{ width: '100%', height: '100%', justifyContent: 'center' }} >
            {responseState === "loading" && (
              <ClipLoader color={'#123abc'} loading={true} size={150} />
            )}
            {responseState === "error" && (
              <Col className='error-col'>
                <p style={{ color: 'red', textAlign: 'center' }}>An error occurred while loading data. Please try again later.</p>
              </Col>
            )}
            {responseState === "done" && (
              <BasicChartCard tickerInfo={tickerInfo} tickerData={TickerData} />
            )}
            {responseState !== "loading" && responseState !== "error" && responseState !== "done" && responseState !== "deleted" && (
              <p style={{ color: 'gray', textAlign: 'center' }}>Unknown state</p>
            )}
          </Row>
        	</CardBody>
      </Card>
			</div>
		)
});
