import axios from 'axios';
import {AuthContext} from '../contexts/AuthContext'; 
import {useContext} from 'react';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
const api = axios.create({
  baseURL: `${API_BASE_URL}/api`,
});
  

// Axios 인터셉터 설정
api.interceptors.request.use(
    async (config) => {
      const accessToken = localStorage.getItem('access_token');  
      // 토큰이 존재하면 요청 헤더에 추가
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
  
      // 토큰 만료 여부 확인
      const isTokenExpired = await isAccessTokenExpired(accessToken);
      console.log("isTokenExpired", isTokenExpired)
      //const isTokenExpired = false;
      // 토큰이 만료되었고, 리프레시 토큰이 있다면 새로운 Access Token 요청
      if (isTokenExpired) {
        try {
          const newAccessToken = await refreshAccessToken();
          // 새로운 토큰을 로컬 스토리지에 저장
          localStorage.setItem('access_token', newAccessToken);
          config.headers.Authorization = `Bearer ${newAccessToken}`;
          console.log("check2")	
        } catch (error) {
          // 리프레시 토큰으로 새로운 토큰을 가져오지 못하면 로그아웃 또는 다른 처리 수행
          // 로그아웃 또는 다른 처리 로직 추가
          localStorage.clear();
          const Auth = useContext(AuthContext);
          Auth.logout();
          console.log("check")	
          console.error('Error refreshing token:', error);
        }
      }
      
  
      return config;
    },
    (error) => {
      const Auth = useContext(AuthContext);
      Auth.logout();
      return Promise.reject(error);
    }
  );


  

async function isAccessTokenExpired(accessToken) {
  // 토큰이 없으면 만료된 것으로 간주
  if (!accessToken) {
    return true;
  }

  try {
    //require는 Node.js 환경에서 다른 파일이나 모듈을 불러올 때 사용되는 키워드
    const response = await axios.get(`${API_BASE_URL}/api/auth/verify-token`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }) 
    if(response.data.valid)
        return false;
    else 
        return true;
  } catch (error) {
    // 토큰 디코딩에 실패하면 만료된 것으로 간주
    return true;
  }
}

// Access Token 갱신 함수
async function refreshAccessToken() {
  try {
    
    const response = await axios.post(
      `${API_BASE_URL}/api/auth/refresh-access-token/`,
      null, //post에 null로 데이터를 보내줘야 쿠키도 같이 전송됨!
      {
        withCredentials: true
    }
  );

    return response.data.access_token;
  } catch (error) {
    throw error;
  }
}



export default api;
