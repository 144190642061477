import React, {useEffect} from 'react'
import styles from '../../../styles/navbar.module.css';
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';
import NavbarBrand from '../../atoms/NavbarBrand';


const Navbar = ({isOpen, toggleMenu, headerText}) => {
  
  useEffect(() => {
    console.log('HeaderText has changed:', headerText);
    // Add any additional logic you want to execute when headerText changes
  }, [headerText]);

  return (
    <nav className={styles['navbar']}>
      {isOpen ? (<div className={styles['icon-container']} onClick={toggleMenu}>
            <AiIcons.AiOutlineClose size="2em" color="white" />
      </div>) : 
        (<div className={styles['icon-container']} onClick={toggleMenu} fontWeight='bold'>
        <FaIcons.FaBars size="2em" color="white"/>
      </div>)
      }
      <NavbarBrand >{headerText}</NavbarBrand>
    </nav>
  )
}

export default Navbar