import React, { useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import SignIn from '../pages/Auth/SignIn';
import Sidebar from '../components/organisms/Sidebar/Sidebar'
import Navbar from '../components/organisms/Navbar/Navbar'
import global_styles from '../styles/global.module.css'
import { AuthContext } from '../contexts/AuthContext';
import { BackgroundColorContext } from "../contexts/BackgroundColorContext";

function PrivateRoutes() {
    //sidebar를 여닫기 위한 state 
    const [isOpen, setOpen] = useState(true);
    const toggleMenu = () => {
        setOpen(!isOpen);
    };
    // 로그인, 로그아웃을 위한 state
    const Auth = useContext(AuthContext);
    // 메뉴선택을 위한 state
    const [headerText, setHeaderText] = useState('Home');

    // 화면에 랜더링 되는 html 영역
    // outlet제외한 부분이 layout의 역활을 하고, outlet부분은 링크에 해당하는 html source가 들어감

   // const mainPanelStyles = {
   //     backgroundColor: 'color',  // color 변수에 맞게 수정하세요
   //     transition: 'transform 0.4s ease',  // transform 속성에 애니메이션을 적용합니다
   //     transform: isOpen ? 'translateX(260px)' : 'translateX(0)',  // isOpen에 따라 이동합니다
   //   };

    if (Auth.isLoggedIn) {
        return (
            <>
                <BackgroundColorContext.Consumer>
                    {({ color, changeColor }) => (
                        <>
                            <Sidebar isOpen={isOpen} toggleMenu={toggleMenu} setHeaderText={setHeaderText}></Sidebar>
                            <div className={`${global_styles['main-panel']} ${isOpen ? global_styles.isOpen : ''}`} data={color}>
                                <Navbar isOpen={isOpen} toggleMenu={toggleMenu} headerText={headerText}/>
                                <Outlet />
                            </div>
                        </>
                    )}
                </BackgroundColorContext.Consumer>
            </>
        );
    } else {
        return <SignIn />;
    }
}

export default PrivateRoutes;
