import React from 'react';
import styles from '../../../styles/modal.module.css';

const Modal = ({ message, onClose }) => {
  return (
    <>
    <div className={styles['modal-background']}>
      <div className={styles['modal-container']}>
        <div className={styles['modal-text']}> {message} </div>
        <label className={styles['close']} onClick={onClose}>close</label>
      </div>
    </div>
    </>
  );
};

export default Modal;
