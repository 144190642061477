import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoutes from './utils/PrivateRoutes';
import Home from './pages/Home';
import Content from './pages/Content';
import Strategy from './pages/Strategy';

import ThemeContextWrapper from "./components/organisms/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/organisms/BackgroundColorWrapper/BackgroundColorWrapper";



function App() {
  return (
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <AuthProvider>
          <Router>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path='' element={<Home />} />
                <Route path='content' element={<Content />} />
                <Route path='strategy' element={<Strategy />} />
              </Route>
            </Routes>
          </Router>
        </AuthProvider>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  );
}

export default App;
