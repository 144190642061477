import React from 'react';
import styles from '../../styles/navbar.module.css'

const NavbarBrand = (props) => {
  return (
    <a href="/" className={styles['brand']}>
      {props.children}
    </a>
  );
};

export default NavbarBrand;
