// 외부 패키지 및 모듈 import
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import * as BiIcons from 'react-icons/bi';
// 내부 컴포넌트 및 모듈 import
import api from '../../../services/api';
import { ChartCard } from '../../organisms/Chart/Chart';
import { AddTickerModal } from '../../mocules/modals/TickerModal';
import styles from '../../../styles/dashboard.module.css'
const Dashboard = () => {

  // tickerList를 받아옴 
  // ticker는 id, ticker, source, description, owner로 구성
  const [isAddTickerModalOpen, setIsAddTickerModalOpen] = useState(false);
  const toggleAddTickerModal = () => {
    setIsAddTickerModalOpen(!isAddTickerModalOpen);
  };
  const [tickerList, setTickerList] = useState();

  const fetchUserTickerList = async () => {
    try {
      const response = await api.get('/data/ticker');
      const tickerListData = response.data;
			tickerListData.sort((a, b) => {
				if (a.category < b.category) return -1;
				if (a.category > b.category) return 1;
				return 0;
			});
			setTickerList(tickerListData);
      console.log(tickerListData)
    } catch (error) {
      console.error('Error fetching user tickers:', error);
    }
  };

  useEffect(() => {
    fetchUserTickerList();
  }, []);
  useEffect(() => {
    console.log("this is tickerList:", tickerList);
  }, [tickerList]);

  return (
    <div className={styles['wrapper']}>
    <Container className={styles['container']}>
			<div className={styles['divider']}>
					<h2 className={styles['text']}> &nbsp;  경제지표 차트</h2>
          <button className="btn btn-link btn-lg" onClick={() => setIsAddTickerModalOpen(true)}>
						<BiIcons.BiAddToQueue />
          </button>
          <AddTickerModal isOpen={isAddTickerModalOpen} toggleModal={toggleAddTickerModal} tickerList={tickerList} setTickerList={setTickerList}/>
			</div>
					<Row>
          {tickerList && tickerList.length > 0 ? (
            <>
              {tickerList.map((ticker, index) => (
                  <Col md="12" lg="6" key={ticker.id} style={{ padding: "5px 5px 5px 5px" }}>
                    <ChartCard index={index} tickerInfo={ticker} setTickerList={setTickerList}/>
                  </Col>
              ))}
            </>
          ) : (
            <p>No ticker data available.</p>
          )}
        </Row>
    </Container>
    </div>
  );
};

export default Dashboard;



