import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const QuillEditor = () => {
	const [content, setContent] = useState('');
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		fetchContent();
	}, []);

	const fetchContent = async () => {
		try {
			const response = await axios.get('/api/fetch');
			setContent(response.data.content);
		} catch (error) {
			console.error('Error fetching content:', error);
		}
	};

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleSave = async () => {
		try {
			await axios.post('/api/save', { content });
			console.log('Content saved to database');
			setIsEditing(false);
		} catch (error) {
			console.error('Error saving content:', error);
		}
	};

	return (
		<div>
			{isEditing ? (
				<ReactQuill
					value={content}
					onChange={setContent}
				/>
			) : (
				<div dangerouslySetInnerHTML={{ __html: content }} />
			)}
			{isEditing ? (
				<button onClick={handleSave}>Save</button>
			) : (
				<button onClick={handleEdit}>Edit</button>
			)}
		</div>
	);
};

export default QuillEditor;

